
import { defineComponent, onMounted, ref } from "vue";
import { requiredField } from "@/util/form-rules";
import FormLoading from "../../layout/FormLoading.vue";
import useForm from "@/modules/useForm";
import Divisao from "@/interfaces/Divisao";
import Turma from "@/interfaces/Turma";
import Curso from "@/interfaces/Curso";
import BaseService from "@/services/admin/BaseService";

interface Form {
  texto: string;
  fixado: boolean;
  turmas: string[];
  cursos: string[];
  divisao_id: string;
  data_fixado: string;
}

const baseForm: Form = {
  texto: "",
  fixado: false,
  turmas: [],
  cursos: [],
  divisao_id: "",
  data_fixado: "",
};

export default defineComponent({
  components: { FormLoading },
  props: {
    cursos: { type: Object, required: true },
    turmas: { type: Object, required: true },
  },
  setup(props, { emit }) {
    const tipo = ref<string>("Geral");
    const { show, form, row, loading, submit, destroy, afterSubmit } = useForm(
      "postagens",
      "postagens",
      "a",
      baseForm,
      emit,
      "postagens"
    );
    const divisoes = ref<Divisao[]>([]);

    const getDivisoes = (): void => {
      BaseService.list("divisoes")
        .then((res) => {
          divisoes.value = res.data;
        })
        .catch((err) => {
          console.error(err.response.data.message);
        });
    };

    const open = (data: Divisao | null): void => {
      if (data) {
        row.value = JSON.parse(JSON.stringify(data));
        if (row.value?.id) {
          form.value = {
            texto: row.value.texto,
            fixado: row.value.fixado,
            data_fixado: row.value.data_fixado,
            divisao_id: row.value.divisao_id,
            turmas: row.value.turmas.map((t: Turma) => t.id),
            cursos: row.value.cursos.map((c: Curso) => c.id),
          };
          tipo.value = row.value.tipo;
        } else {
          form.value = JSON.parse(JSON.stringify(baseForm));
          tipo.value = "Geral";
        }
      }
      show.value = true;
    };

    const handleTipo = (): void => {
      form.value.turmas = [];
      form.value.cursos = [];
    };

    onMounted(() => {
      getDivisoes();
    });

    return {
      tipo,
      show,
      open,
      form,
      row,
      requiredField,
      loading,
      submit,
      destroy,
      afterSubmit,
      handleTipo,
      divisoes,
    };
  },
});
